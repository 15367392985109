/**
 * You can add global styles to this file, and also import other style files
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .page-title {
    @apply text-2xl;
    @apply font-bold;
  }

  .page-title-small {
    @apply text-xl;
    @apply font-bold;
  }

  .personalized-message {
    @apply text-2xl;
    @apply font-extralight;
  }

  .body {
    @apply text-sm;
    @apply font-normal;
  }

  .body-bold {
    @apply text-sm;
    @apply font-semibold;
  }

  .p-small-bold {
    @apply text-xs;
    @apply font-semibold;
  }

  .p-small {
    @apply text-xs;
    @apply font-normal;
  }

  .btn-text {
    @apply text-base;
    @apply font-semibold;
    @apply text-center;
  }

  .text-link-bold {
    @apply text-xs md:text-sm;
    @apply font-semibold;
    @apply leading-5;
    @apply text-vartana-blue-120;
    @apply disabled:text-vartana-gray-110;
    @apply cursor-pointer;
  }

  .field-label {
    @apply text-xs;
    @apply font-normal;
    @apply text-vartana-gray-140;
  }

  .field-text {
    @apply text-sm;
    @apply font-normal;
    @apply placeholder:text-sm placeholder:font-normal;
  }

  .body-text {
    @apply text-sm;
    @apply font-normal;
  }

  .field-helper-text {
    @apply text-xxs;
    @apply font-medium;
    @apply leading-4;
  }

  .card-title-bold {
    @apply text-lg;
    @apply font-medium;
  }

  .card-subtitle-bold {
    @apply font-semibold;
    @apply leading-6;
    @apply text-sm md:text-base;
  }

  .card-border-blue {
    @apply border-2 rounded-md border-vartana-blue-80 shadow-lift-3;
  }
}

/*
  We are overriding the height of hello-sign so it's navigation
  does not overlap with intercom button
*/
.web-app .x-hellosign-embedded__modal-content {
  height: 90vh;
}
